export class TextUtils {

    /**
     * Comprobación de cadena de texto vacía
     * @param text cadena a evaluar
     */
    public static isBlank(text: string): boolean {

        if (text == null) { return true; }
        return text.trim().length === 0;
    }
}
