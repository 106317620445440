import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { CredencialUsuarioDto } from '../models/credencial-usuario-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredencialService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de credenciales del usuario
   * @param onSuccess respuesta OK (datos de credenciales)
   * @param onError respuesta KO
   */
   public getCredencialesUsuarioList(onSuccess:WsSuccessFunction<CredencialUsuarioDto[]>, onError: WsErrorFunction<boolean>): void {

    const body = {
      fecha: '',
      aplicacion: environment.applicationName
    };

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.post<CredencialUsuarioDto[]>(environment.apiCredencialesListUrl, body, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Detalle de una credencial
   * @param idCredencial identificador de la credencial
   * @param onSuccess respuesta OK (datos de credencial)
   * @param onError respuesta KO
   */
   public getCredencial(idCredencial: number, onSuccess:WsSuccessFunction<CredencialUsuarioDto>, onError: WsErrorFunction<boolean>): void {

    const body = {
      fecha: '',
      aplicacion: environment.applicationName
    };

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<CredencialUsuarioDto>(environment.apiCredencialUrl + idCredencial, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Descarga de una credencial en PDF (certificado)
   * @param idCredencial identificador de la credencial
   */
  public getCredencialPdf(idCredencial: number, onSuccess:WsSuccessFunction<any>, onError: WsErrorFunction<boolean>): void {

    const body = {
      idCredencial: idCredencial
    };

    this.http.post(environment.apiCredencialPdfUrl, body, { headers: {
      'Content-Type': WebServiceUtils.CONTENT_TYPE,
      'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      },
      observe: 'body',
      responseType: 'blob' }).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Descarga del album de una credencial en PDF 
   * @param idCredencial identificador de la credencial
   */
   public getCredencialAlbum(idCredencial: number, onSuccess:WsSuccessFunction<any>, onError: WsErrorFunction<boolean>): void {

    const body = {
      idCredencial: idCredencial
    };

    this.http.post(environment.apiCredencialAlbumUrl, body, { headers: {
      'Content-Type': WebServiceUtils.CONTENT_TYPE,
      'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      },
      observe: 'body',
      responseType: 'blob' }).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }
}