import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPassword]'
})
export class AppPasswordDirective {
  private _shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.setAttribute('class', 'password-toggle pi pi-eye-slash');
    span.innerHTML = ``;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.setAttribute('class', 'password-toggle pi pi-eye');
      span.innerHTML = '';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.setAttribute('class', 'password-toggle pi pi-eye-slash');
      span.innerHTML = '';
    }
  }

}
