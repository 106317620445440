import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Pipe({ name: 'locale' })
export class LocalePipe implements PipeTransform {

  constructor(private localeService: LocaleService) {}

  /**
   * Convertidor de una etiqueta en texto internacionalizdo
   * @param value Etiqueta a traducir
   * @param args Configuración adicional
   */
  transform(value: string, args?: any): string {
    
    // comprobación clave válida
    if (value == null) { return ''; }

    // Llamada al servicio de traducciones
    const txt: string = this.localeService.getCurrent()[value];

    // Retornar traducción o etiqueta en caso de no encontrar
    return txt != null ? txt : value;
  }
}
