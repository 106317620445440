<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <!-- CAROUSEL -->
    <ngb-carousel class="home-carousel" #carousel *ngIf="images" [interval]="7000" [pauseOnHover]="true">
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
            <img [src]="images[0]" alt="Random first slide">
          </div>
          <div class="carousel-caption">
            <h3>¡Puxa'l Camín!</h3>
            <p>La Credencial de siempre, con un nuevo formato</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img [src]="images[1]" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <h3>Registra tu paso por el Camino</h3>
                <p>Nuevo sistema de geolocalización de sellos</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img [src]="images[2]" alt="Random third slide">
            </div>
            <div class="carousel-caption">
                <h3>Una experiencia única</h3>
                <p>Imprime la credencial de tu paso por el Camino</p>
            </div>
        </ng-template>
    </ngb-carousel>
    <!-- END CAROUSEL -->


    <!-- FEATURES -->
    <section class="features">
        <div class="contain">
            <div class="feature-item">
                <fa-icon [icon]="faRegistro"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_registro_title'|locale}}</span></h3>
                    <p>{{'feature_registro_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faCaminos"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_camino_title'|locale}}</span></h3>
                    <p>{{'feature_camino_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faServicios"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_servicios_title'|locale}}</span></h3>
                    <p>{{'feature_servicios_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faIncidencias"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_incidencias_title'|locale}}</span></h3>
                    <p>{{'feature_incidencias_text'|locale}}</p>
                </div>
            </div>
        </div>
    </section>
    <!-- END FEATURES -->

    <!-- ROUTES -->
    <section class="routes">
        <div class="route-item item-1">
            <h3 class="big-heading">CAMINOS <sup class="subtour"> Gijón, Oviedo...</sup></h3>
            <p class="content">Empieza desde tu ciudad de origen</p>
        </div>
        <div class="route-item item-2">
            <h3 class="big-heading">MEDIOS <sup class="subtour"> A pie, En bici...</sup></h3>
            <p class="content">La credencial se adapta a ti</p>
        </div>
        <div class="route-item item-3">
            <h3 class="big-heading">SERVICIOS</h3>
            <p class="content">Información de interés actualizada</p>
        </div>
    </section>
    <!-- END ROUTES -->

    <!-- MAP -->
    <section class="map">
        <div class="contain">
            <div class="img-map">
                <img alt="top-rated" class="img-responsive" src="../../../assets/img/basilica.png">
            </div>
            <div class="info-map">
                <h3 class="big-heading">Asociación de Amigos del Camín de Covadonga</h3>
                <span class="sub-heading-content">Vive la pasión del Camín</span>
                <span class="devider-left"></span>
                <p>Estamos en proceso de construcción de una Asociación de Amigos del Camín en cualquiera de sus variantes:</p>
                <div class="tag-salida">
                    <a href="#" class="list-country">
                        Oviedo 
                    </a>
                    <a href="#" class="list-country">
                        Gijón 
                    </a>
                    <a href="#" class="list-country">
                        Cangas 
                    </a>
                    <a href="#" class="list-country">
                        Covadonguinos <sup>(6 variantes)</sup>
                    </a>
                </div>
                <a href="mailto:soporte@camindecovadonga.com" class="btn-content">
                    AVISARME CUANDO ESTÉ DISPONIBLE
                </a>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>