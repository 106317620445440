import { Component, OnInit } from '@angular/core';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { LoadingService } from 'src/app/services/loading.service';

import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { Router } from '@angular/router';

import { CredencialService } from 'src/app/services/credencial.service';
import { CredencialUsuarioDto } from 'src/app/models/credencial-usuario-dto';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
  providers: [ConfirmationService]
})
export class RegistroComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
  public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  credenciales: CredencialUsuarioDto[] = [];
  errorMessage: any = '';
  loading: boolean;
  numCredenciales: number;

  map: google.maps.Map;
  options: any;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private credencialService: CredencialService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('registro_title');
    this.caption = this.locale.transform('registro_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.options = {
      center: {lat: 42.373603728854896, lng: -3.9160203846655706},
      zoom: 7,
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      maxZoom: 15,
      minZoom: 7,
      fullscreenControl: false,
      keyboardShortcuts: false
    };

    this.reloadDataCredenciales();
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
  childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
      });
        break;
      }
      default:
        break;
    }
  }

  setMap(event, cred: CredencialUsuarioDto) {
    cred.map = event.map;
  }

  reloadDataCredenciales() {
    this.credencialService.getCredencialesUsuarioList(
      (resp: CredencialUsuarioDto[]) => {
        this.numCredenciales = resp.length;
        this.credenciales = resp;
        this.credenciales = this.credenciales.filter(element => {
          return element.camino != null;
        });
        this.credenciales.sort(function sortFunction(a, b){
          return a.fechaCompra < b.fechaCompra ? 1 : -1;
        });
        this.credenciales.forEach(cred => {
          cred.markers = [];
          const marker = new google.maps.Marker({
            position: {lat: cred.latitud, lng: cred.longitud},
            animation: google.maps.Animation.DROP
          });
          cred.markers.push(marker);
          const bounds = new google.maps.LatLngBounds();
          cred.markers.forEach(marker => {
                bounds.extend(marker.getPosition());
            });
          setTimeout(() => { // map will need some time to load
            cred.map.fitBounds(bounds); // Map object used directly
          }, 200);
          if (navigator.geolocation) {
            let geocoder = new google.maps.Geocoder();
            let latlng = new google.maps.LatLng(cred.latitud, cred.longitud);
            let request = { location: latlng };
            geocoder.geocode(request, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                let result = results[0];
                let rsltAdrComponent = result.address_components;
                if (result != null) {
                  cred.lugar = rsltAdrComponent[1].long_name + ", " + rsltAdrComponent[2].long_name;
                } else {
                  alert('No address available!');
                }
              }
            });
          }
        })
        // ocultar pantalla de carga
        setTimeout(() => {
          this.loadingService.hideLoading();
        });
      },
      (resp: boolean, err: HttpErrorResponse) => {

      });
  }

  handleMapClick(event, cred: CredencialUsuarioDto) {
    this.router.navigateByUrl('/credencial/' + cred.id);
  }
}
