import { Message, MessageType, ToastService } from '../../services/toast.service';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

import { SubscriptionManager } from '../../classes/subscription-manager';

@Component({
  selector: 'app-toast-panel',
  templateUrl: './toast-panel.component.html',
  styleUrls: ['./toast-panel.component.scss']
})
export class ToastPanelComponent implements OnInit {

  public messages = new Array<Message>();
  private subs = new SubscriptionManager();

  constructor(
    private messagesService: ToastService, private renderer: Renderer2, private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.subs.add(this.messagesService.receiveMessages().subscribe(
      (message: Message) => {
        this.messages.push(message);
        this.autoRemoveAlert(message);
      }
    ))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribeAll();
  }

  getMessageType(tipo: MessageType): string {
    return tipo;
  }

  close(m: Message): void {
    const pos = this.messages.indexOf(m);

    if (pos !== -1) {
      this.messages.splice(pos, 1);
    }
  }

  private autoRemoveAlert(m: Message): void {
    setTimeout( () => {
      this.close(m);
    }, m.timeout );
  }

}
