import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CredencialUsuarioDto } from 'src/app/models/credencial-usuario-dto';
import { SelloUsuarioDto } from 'src/app/models/sello-usuario-dto';
import { ServicioDto } from 'src/app/models/servicio-dto';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ServiciosService } from 'src/app/services/servicios.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';

@Component({
  selector: 'app-servicio-detail',
  templateUrl: './servicio-detail.component.html',
  styleUrls: ['./servicio-detail.component.scss'],
  providers: [ConfirmationService]
})
export class ServicioDetailComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  options: any;

  servicio: ServicioDto;
  errorMessage: any = '';
  loading: boolean;
 
  map: google.maps.Map;
  markers: any[] = [];

  constructor(private locale: LocalePipe,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private serviciosService: ServiciosService) { }

  ngOnInit(): void {
    // establecer no cargado hasta inicialización de componente
    this.loaded = false;
  
    // Añadir titulos a la cabecera
    this.title = this.locale.transform('servicios_title');
      this.caption = this.locale.transform('servicios_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.options = {
      center: {lat: 42.373603728854896, lng: -3.9160203846655706},
      zoom: 7,
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      maxZoom: 15,
      minZoom: 7,
      fullscreenControl: false,
      keyboardShortcuts: false
    };

    if(this.route.snapshot.paramMap.get('id')) {
      this.route.params.subscribe((params: Params) => {
        this.serviciosService.getServicio(params['id'],
          (resp: ServicioDto) => {
            this.servicio = resp;
            this.markers = [];
            if (navigator.geolocation) {
              let geocoder = new google.maps.Geocoder();
              let latlng = new google.maps.LatLng(this.servicio.latitud, this.servicio.longitud);
              let request = { location: latlng };
              geocoder.geocode(request, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  let result = results[0];
                  let rsltAdrComponent = result.address_components;
                  if (result != null) {
                    this.servicio.lugar = rsltAdrComponent[1].long_name + ", " + rsltAdrComponent[2].long_name + ", " + rsltAdrComponent[3].long_name;
                    this.markers.push(this.generateMarker(this.servicio));
                    this.drawMarkers(this.servicio);
                  } else {
                    alert('No address available!');
                  }
                }
              });
            }
            setTimeout(() => {
              this.loadingService.hideLoading();
            });
          },
          (resp: boolean, err: HttpErrorResponse) => {

        });
      });
    }
  }

  setMap(event) {
    this.map = event.map;
  }

  generateMarker(servicio: any) {
    // Cambiar imagen de marcador según categoria
    const image = servicio.categoria.icono;

    const infoWindowContent = `<div id="infoWindowContent">
                                 <div class="infoWindowTitle"><strong>${servicio.nombre}</strong></div>
                                   <div id="infoWindowBodyContent">${servicio.localidad}, ${servicio.provincia}</div>
                                   <div id="infoWindowCategory"><img src="${image}"/>${servicio.categoria.nombre}</div>
                               </div>`;

    const infoWindow = new google.maps.InfoWindow({
     content: infoWindowContent,
    });

    const marker = new google.maps.Marker({
      position: {lat: servicio.latitud, lng: servicio.longitud},
      animation: google.maps.Animation.DROP,
      icon: image
    });

    marker.addListener('click', () => {
      if(marker.get('open')){
        infoWindow.close();
        marker.set('open', false);
      } else {
        infoWindow.open(this.map, marker);
        marker.set('open', true);
      }
    });

    infoWindow.addListener('closeclick', () => {
      marker.set('open', false);
    });

    return marker;
  }

  drawMarkers(datos: ServicioDto) {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });

    setTimeout(() => { // map will need some time to load
      this.map.fitBounds(bounds); // Map object used directly
     }, 200);
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
        });
        break;
      }
      default:
        break;
    }
  }
}
