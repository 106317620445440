import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { ServicioDto } from '../models/servicio-dto';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de servicios disponibles
   * @param onSuccess respuesta OK (datos de servicios)
   * @param onError respuesta KO
   */
  public getServiciosAplicacionList(onSuccess:WsSuccessFunction<ServicioDto[]>, onError: WsErrorFunction<boolean>): void {

    const body = {
      fecha: '',
      aplicacion: environment.applicationName
    };

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.post<ServicioDto[]>(environment.apiServiciosListUrl, body, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Detalle de un servicio
   * @param idServicio identificador del servicio
   * @param onSuccess respuesta OK (datos del servicio)
   * @param onError respuesta KO
   */
  public getServicio(idServicio: number, onSuccess:WsSuccessFunction<ServicioDto>, onError: WsErrorFunction<boolean>): void {

    const opts = {
      headers: {
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<ServicioDto>(environment.apiServicioUrl + idServicio, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }
}
