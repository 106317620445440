import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { LoadingComponent } from './layout/loading/loading.component';
import { ToastPanelComponent } from './layout/toast-panel/toast-panel.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalePipe } from './pipes/locale.pipe';
import { BackToTopComponent } from './layout/back-to-top/back-to-top.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RestInterceptor } from './classes/rest-interceptor';
import { SubHeaderComponent } from './layout/sub-header/sub-header.component';
import { ProjectComponent } from './components/project/project.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactComponent } from './components/contact/contact.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { GMapModule } from 'primeng/gmap';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';

import { AgmCoreModule } from '@agm/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { AppPasswordDirective } from './directives/app-password.directive';
import { RegistroComponent } from './components/private/registro/registro.component';
import { ServiciosComponent } from './components/private/servicios/servicios.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CredentialDetailComponent } from './components/private/registro/credential-detail/credential-detail.component';
import { DatePipe } from '@angular/common';
import { LightboxModule } from 'ngx-lightbox';
import { ServicioDetailComponent } from './components/private/servicios/servicio-detail/servicio-detail.component';
import { LegalComponent } from './components/legal/legal.component';
import { SecurityComponent } from './components/security/security.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { RecoverComponent } from './components/recover/recover.component';
import { ConfirmComponent } from './components/confirm/confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    LoadingComponent,
    ToastPanelComponent,
    HeaderComponent,
    FooterComponent,
    LocalePipe,
    BackToTopComponent,
    SubHeaderComponent,
    ProjectComponent,
    FaqComponent,
    ContactComponent,
    AppPasswordDirective,
    RegistroComponent,
    ServiciosComponent,
    CredentialDetailComponent,
    ServicioDetailComponent,
    LegalComponent,
    SecurityComponent,
    CookiesComponent,
    RecoverComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-7qn7Bt1vtb2-nOLbve79j7MeSBllKFQ'
    }),
    NgbModule,
    TabViewModule,
    AccordionModule,
    PasswordModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    TableModule,
    GMapModule,
    DropdownModule,
    CardModule,
    MatGridListModule,
    FlexLayoutModule,
    LightboxModule,
    CheckboxModule
  ],
  entryComponents: [
    RegisterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LocalePipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: RestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
