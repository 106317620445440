<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=0 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="project-content">
        <div class="container">
            <h2>La Credencial de <b>siempre</b>, ahora con un nuevo <b>formato</b></h2>
            <p>El Camín de Covadonga es el nombre con el que se conocen las rutas de peregrinación al Santuario de Covadonga, donde se encuentra la Virgen de Covadonga o Santina, como cariñosamente la denominan los asturianos.</p>
            <p>En la Santa Cueva, alojamiento permanente de la Santina, se hallan además los restos del Rey Pelayo, otro icono de Covadonga, España y Europa, por haber sido el Rey que luchó y venció a los musulmanes en las montañas de Covadonga, iniciando el proceso de La Reconquista en la península ibérica, significando con ello este santo lugar como la Cuna de España.</p>
            <p>Esta Credencial acredita tu condición de peregrino. En ella, además de tus datos, se registran los sellos relacionados con tu itinerario.</p>
            <p>La peregrinación a Covadonga supone un compromiso personal, fundamentado en motivaciones diversas, con un objetivo final de prospección interior. Abunda en ello y comparte con tu entorno la experiencia, destacando aquellos valores y vivencias extraídos de ella.</p>
            <p>Durante el recorrido, respeta el paisaje, se fraterno con tus compañeros y personas que encuentres en el camín.</p>
            <p>No dejes desperdicios y, si puedes, recoge aquello que otros hayan dejado o perdido durante su trayecto. Conservar el camín es una labor de todos. Ayudar a otros y al medioambiente es siempre gratificante, un “milagro humano” digno de ensalzar y fomentar.</p>
            <p>Cuando llegues al Santuario, lo primero que debes hacer es visitar a la Santina en la Santa Cueva. Después, puedes realizar una ofrenda de flores o velas como muestra de amor y devoción por ella.</p>
            <p>También es recomendable que pongas tus pies ante el monumento a Pelayo, frente a la Basílica, como testimonio de tu presencia en la Cuna de España.</p>
            <p>Esta Credencial da acceso a la Certificación Oficial de haber realizado el camín y peregrinación al Santuario de Covadonga (Santina) y a la Cuna de España (Cuna). Puedes obtenerlas en la web www.camindecovadonga.es una vez hayas llegado a Covadonga.</p>
            <p></p>
            <p></p>
            <p><b>¡¡ Puxa`l Camín !!</b></p>
        </div>
    </section>
    <app-footer></app-footer>
</div>