import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Message {
  type: MessageType;
  msg: string;
  timeout: number;
}

export enum MessageType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
  dark = 'dark',
}

@Injectable({ providedIn: 'root' })
export class ToastService {

  private message = new Subject<Message>();

  constructor() { }

  newMessage(type: MessageType, msg: string, timeout: number = 4000): void {
    this.message.next({
      type,
      msg,
      timeout
    } as Message);
  }

  receiveMessages(): Observable<Message> {
    return this.message.asObservable();
  }
}
