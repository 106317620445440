import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SessionService } from "../services/session.service";
import { WebServiceUtils } from "../utils/web-service-utils";

@Injectable()
export class RestInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // Clonar la petición y añadir las cabeceras genéricas
    req = req.clone({
      headers: req.headers.set(WebServiceUtils.HEADER_CONTENT_TYPE, WebServiceUtils.CONTENT_TYPE)
    });

    // mandar la petición clonada al manejador de peticiones REST.
    return next.handle(req);
  }
}
