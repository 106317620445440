<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="legal-content">
        <div class="container">
            <h2>AVISO LEGAL</h2>
            <p>El presente aviso legal recoge las condiciones generales que rigen el acceso y el uso del sitio web <a href="www.camindecovadonga.com">www.camindecovadonga.com</a>, en adelante “sitio web”. El uso de este sitio web implica la expresa y plena aceptación de estas condiciones generales.</p>
            <h4>Propiedad intelectual</h4>
            <p>El dominio en Internet camindecovadonga.com es titularidad de Camino Aplicación, S.L.<br/>
            Los derechos de propiedad intelectual de este sitio web, su diseño gráfico y los códigos que contiene, son titularidad de Camino Aplicación, a no ser que se indique una titularidad diferente.<br/>
            La reproducción, distribución, comercialización o transformación no autorizada de estas obras constituye una infracción de los derechos de propiedad intelectual. Igualmente, todas las marcas o signos distintivos contenidos en el sitio web están protegidos por ley.</p>
            <h4>Términos de uso</h4>
            <p>No se permite la modificación del sitio web ni su contenido. La utilización indebida o no autorizada, así como los daños o perjuicios ocasionados a consecuencia del incumplimiento de esta obligación, dará lugar a las acciones que legalmente correspondan.<br/>
            Está prohibido el envío de correo no solicitado dirigido a las cuentas de correo electrónico utilizadas en este sitio web.<br/>
            Está prohibido utilizar este sitio web con fines comerciales o de manera inadecuada.<br/>
            El usuario no podrá acceder al sitio web de modo que dañe, deteriore, inutilice o sobrecargue los servicios y/o la información ofrecida.</p>
            <h4>Limitación de responsabilidad</h4>
            <p>El presente sitio web tiene por objeto poner a disposición de los usuarios ciertos servicios e informaciones relacionadas con Camino Aplicación. Se persigue el objetivo de velar por la actualidad y exactitud de dicha información, no obstante Camino Aplicación no asume responsabilidad alguna en relación con la información incluida en estas páginas.<br/>
            Tanto el acceso al sitio web como el uso que pueda hacerse de la información contenida es exclusivamente responsabilidad del usuario que lo realiza.<br/>
            Camino Aplicación no responderá por ninguna consecuencia, daño o perjuicio que se pudiera derivar del acceso al sitio web o uso de información, excepto actuaciones que resulten de las disposiciones legales aplicables.</p>
            <h4>Disponibilidad del servicio</h4>
            <p>Camino Aplicación no puede garantizar plenamente el funcionamiento de la red de comunicaciones y, por tanto, no asume responsabilidad alguna sobre la disponibilidad de su servidor; que podrá ser desconectado sin previo aviso.<br/>
            Se harán todos los esfuerzos para que el impacto por tareas de mantenimiento del sitio web sea el mínimo posible. Asimismo, Camino Aplicación no se responsabiliza de ningún daño o perjuicio sufrido por el usuario que se derive del acceso a este servidor o del uso de información o aplicaciones en él contenidos.</p>
            <h4>Vínculos a otros sitios web</h4>
            <p>Los enlaces contenidos en el sitio web pueden dirigir a páginas de terceros. Camino Aplicación no asume ninguna responsabilidad por el contenido, información o servicios que puedan aparecer en dichos sitios.</p>
            <h4>Modificaciones</h4>
            <p>Camino Aplicación se reserva el derecho a realizar cambios en el sitio web sin previo aviso, con el objeto de actualizar, corregir, modificar, añadir o eliminar su contenido o diseño.</p>
            <h4>Seguridad y privacidad de los datos personales</h4>
            <p>Enlace a la <a routerLink="/seguridad">Política de seguridad</a>.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>