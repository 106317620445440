import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CredencialUsuarioDto } from 'src/app/models/credencial-usuario-dto';
import { SelloUsuarioDto } from 'src/app/models/sello-usuario-dto';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { CredencialService } from 'src/app/services/credencial.service';
import { SelloService } from 'src/app/services/sello.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { DatePipe } from '@angular/common';

import { Lightbox } from 'ngx-lightbox';

@Component({ selector: 'app-credential-detail', templateUrl: './credential-detail.component.html', styleUrls: ['./credential-detail.component.scss'], providers: [ConfirmationService] })
export class CredentialDetailComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

   private _albums:any[] = [];

   //Títulos
   title: string;
   caption: string;
 
   credencial: CredencialUsuarioDto;
   sellos: SelloUsuarioDto[] = [];
   errorMessage: any = '';
   loading: boolean;
   numCredenciales: number;
 
   map: google.maps.Map;
   options: any;
   markers: any[] = [];
   layers: any[] = [];
 
   constructor(private locale: LocalePipe,
     private loadingService: LoadingService,
     private confirmationService: ConfirmationService,
     private authService: AuthService,
     private sessionService: SessionService,
     private router: Router,
     private route: ActivatedRoute, 
     private credencialService: CredencialService,
     private selloService: SelloService,
     public datepipe: DatePipe,
     private _lightbox: Lightbox) { }
 
   ngOnInit(): void {
     
    // establecer no cargado hasta inicialización de componente
    this.loaded = false;
 
     // Añadir titulos a la cabecera
     this.title = this.locale.transform('registro_title');
     this.caption = this.locale.transform('registro_caption');
 
     // establecer cargado tras inicialización de componente
     this.loaded = true;
 
     this.options = {
       center: {lat: 42.373603728854896, lng: -3.9160203846655706},
       zoom: 7,
       mapTypeId: 'terrain',
       disableDefaultUI: true,
       maxZoom: 15,
       minZoom: 7,
       fullscreenControl: false,
       keyboardShortcuts: false
     };

    if(this.route.snapshot.paramMap.get('id')) {
      this.route.params.subscribe((params: Params) => {
        this.credencialService.getCredencial(params['id'],
          (resp: CredencialUsuarioDto) => {
            this.credencial = resp;
            this.selloService.getSellosCredencialList(this.credencial.id,
              (resp: SelloUsuarioDto[]) => {
                this.sellos = resp;
                this.sellos.sort(function sortFunction(a, b){
                  return a.fecha < b.fecha ? 1 : -1;
                });
                this.markers = [];
                this.sellos.forEach(sello => {
                  if (navigator.geolocation) {
                    let geocoder = new google.maps.Geocoder();
                    let latlng = new google.maps.LatLng(sello.latitud, sello.longitud);
                    let request = { location: latlng };
                    geocoder.geocode(request, (results, status) => {
                      console.log("Sello: " + results);
                      console.log("Status: " + status);
                      if (status === google.maps.GeocoderStatus.OK) {
                        let result = results[0];
                        let rsltAdrComponent = result.address_components;
                        console.log("Resultado: " + rsltAdrComponent);
                        if (result != null) {
                          sello.lugar = rsltAdrComponent[1].long_name + ", " + rsltAdrComponent[2].long_name;
                          this.markers.push(this.generateMarker(sello));
                        } else {
                          alert('No address available!');
                        }
                      }
                    });
                  }
                });

                let caminoSlug = this.credencial.camino.nombre.toLowerCase().replace(/ /g,'-').replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u');
                let src = "https://firebasestorage.googleapis.com/v0/b/covadonga-dev.appspot.com/o/kml%2F" + caminoSlug + ".kml?alt=media";
                this.layers.push(new google.maps.KmlLayer({clickable: false, map: this.map, url: src}));
                
                // ocultar pantalla de carga
                setTimeout(() => {
                  this.drawMarkers();
                  this.loadingService.hideLoading();
                }, 2000);
              },
              (resp: boolean, err: HttpErrorResponse) => {

              });
          },
          (resp: boolean, err: HttpErrorResponse) => {

          });
      });
    }
  }

  setMap(event) {
    this.map = event.map;
  }

  openSello(sello: SelloUsuarioDto) {
    this._albums = [];
    const selloDate = this.datepipe.transform(sello.fecha, 'dd/MM/yyyy HH:mm')
    const album = {
      src: sello.imagen,
      caption: sello.lugar + ", " + selloDate
    };

    this._albums.push(album);
    this._lightbox.open(this._albums, 0);
  }

   /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
  childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
      });
        break;
      }
      default:
        break;
    }
  }

  generateMarker(sello: any) {
    const selloDate = this.datepipe.transform(sello.fecha, 'dd/MM/yyyy HH:mm')
    const infoWindowContent = `<div class="infoWindowContent">
                                <div class="infoWindowCategory"><img src="${sello.imagen}"/></div>
                                <div class="infoContent">
                                  <div class="infoWindowTitle"><strong>${selloDate}</strong></div>
                                  <div class="infoWindowBodyTitle"><i class="pi pi-marker"></i>${sello.latitud}, ${sello.longitud}</div>
                                  <div class="infoWindowBodyContent">${sello.lugar}</div>
                                </div>
                               </div>`;

    const infoWindow = new google.maps.InfoWindow({
     content: infoWindowContent,
    });

    const marker = new google.maps.Marker({
      position: {lat: sello.latitud, lng: sello.longitud},
      animation: google.maps.Animation.DROP,
      icon: '../assets/img/pin.png'
    });

    marker.addListener('click', () => {
      if(marker.get('open')){
        infoWindow.close();
        marker.set('open', false);
      } else {
        infoWindow.open(this.map, marker);
        marker.set('open', true);
      }
    });

    infoWindow.addListener('closeclick', () => {
      marker.set('open', false);
    });

    return marker;
  }

  drawMarkers() {
    const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
          bounds.extend(marker.getPosition());
      });

      setTimeout(() => { // map will need some time to load
          this.map.fitBounds(bounds); // Map object used directly
      }, 200);
  }

  printCredencial() {
    this.credencialService.getCredencialPdf(this.credencial.id, 
      (resp: any) => {
        
        this.downloadFile(resp, "application/pdf", "certificado.pdf");
      },
      (resp: boolean, error: HttpErrorResponse) => {
        
      }
    );
  }

  printAlbum() {
    this.credencialService.getCredencialAlbum(this.credencial.id, 
      (resp: any) => {
        
        this.downloadFile(resp, "text/html", "album.html");
      },
      (resp: boolean, error: HttpErrorResponse) => {
        
      }
    );
  }

  downloadFile(data: any, type: string, name: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(new Blob([data]));
    a.href = objectUrl
    a.download = name;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
