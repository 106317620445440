<div class="main-content">
    <app-header [active]=1 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <div class="title-content">
        <h2>Relación de servicios disponibles</h2>
        <span class="subtitle">Consulta el detalle de los servicios que encontrarás a lo largo del Camino. Teléfonos, horarios,...  todo a tu alcance</span>
    </div>
    <section class="servicios-content">
        <p-gmap #gmap (onMapReady)="setMap($event)" [options]="options" [overlays]="markers" [style]="{'width':'100%','height':'300px'}"></p-gmap>
        <div class="table-section">
            <p-table #dt [(value)]="servicios" (onPage)="onPageChange($event)"
            [paginator]="true" paginatorPosition = "bottom" [autoLayout]="true" [rows]="rows" [showCurrentPageReport]="true" [loading]="loading" [(first)]="first"
            dataKey="id" [filterDelay]="0" (onFilter)="onTableEvent($event)" (onSort)="onTableEvent($event)" currentPageReportTemplate="{first} - {last} de {totalRecords} servicios">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="nombre">Nombre<p-sortIcon field="nombre"></p-sortIcon></th>
                        <th pSortableColumn="categoria">Categoría<p-sortIcon field="categoria.nombre"></p-sortIcon></th>
                        <th pSortableColumn="localidad">Localidad<p-sortIcon field="localidad"></p-sortIcon></th>
                        <th pSortableColumn="provincia">Provincia<p-sortIcon field="provincia"></p-sortIcon></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th><input pInputText type="text" (input)="dt.filter($event.target?$event.target.value:'', 'nombre', 'startsWith')" placeholder="Nombre" class="p-column-filter"></th>
                        <th>
                            <p-dropdown [options]="cats" (onChange)="dt.filter($event.value, 'categoria.nombre', 'equals')" styleClass="p-column-filter" placeholder="Seleccionar" [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                <img [alt]="option.label" src="{{option.icon}}" width="32" style="vertical-align: middle" />
                                <span class="p-ml-1">{{option.label}}</span>
                            </ng-template>
                            </p-dropdown>
                        </th>
                        <th><input pInputText type="text" (input)="dt.filter($event.target?$event.target.value:'', 'localidad', 'startsWith')" placeholder="Localidad" class="p-column-filter"></th>
                        <th><input pInputText type="text" (input)="dt.filter($event.target?$event.target.value:'', 'provincia', 'startsWith')" placeholder="Provincia" class="p-column-filter"></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-servicio>
                    <tr>
                        <td class="center">
                            <div class="servicio-image">
                                <img src="assets/img/placeholder.png" alt="imagen del servicio"/>
                            </div>
                        </td>
                        <td>{{servicio.nombre}}</td>
                        <td><img [alt]="servicio.categoria.nombre" src="{{servicio.categoria.icono}}" width="32" style="vertical-align: middle" />{{servicio.categoria.nombre}}</td>
                        <td>{{servicio.localidad}}</td>
                        <td>{{servicio.provincia}}</td>
                        <td>
                            <div class="servicio-actions">
                                <button (click)="showServicioInfo(servicio)">MÁS INFORMACIÓN</button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </section>
    <app-footer></app-footer>
</div>