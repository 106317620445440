import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {

  /**
   * Petición actual cargando
   */
  private loading: boolean = false;

  public setLoading() {
    this.loading = true;
  }

  public hideLoading() {
    this.loading = false;
  }

  public isLoading(): boolean {
    return this.loading;
  }
}
