<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="cookies-content">
        <div class="container">
            <h2>POLÍTICA DE COOKIES</h2>
            <p>Este sitio web, al igual que la mayoría de los sitios en Internet, utiliza cookies para mejorar y optimizar su experiencia de navegación.<br/>
            A continuación, encontrará información detallada sobre qué son las cookies, qué tipo de cookies utiliza este sitio web y cómo puede desactivarlas en su navegador o bloquear la instalación de cookies de terceros, específicamente.</p>
            <h4>¿Qué son las cookies?</h4>
            <p>Las cookies son archivos que los sitios web o las aplicaciones instalan en el navegador o en su dispositivo (smartphone, tablet o televisión conectada) mientras navega por el sitio web o por la aplicación, y sirven para almacenar información sobre su visita.<br/>
            Este sitio web utiliza cookies para:</p>
            <ul>
                <li>Asegurar que las páginas web pueden funcionar correctamente.</li>
                <li>Almacenar sus preferencias, como el idioma que ha seleccionado o el tamaño de letra.</li>
                <li>Conocer su experiencia de navegación.</li>
                <li>Recopilar información estadística anónima, como qué páginas ha visitado o cuánto tiempo ha permanecido en el sitio web.</li>
            </ul>
            <p>El uso de cookies permite optimizar la navegación, adaptando la información y los servicios ofrecidos a sus intereses, para proporcionarle una mejor experiencia siempre que visita el sitio web.</p>
            <h4>Tipología, finalidad y funcionamiento</h4>
            <p>Las cookies, en función de su permanencia, pueden dividirse en cookies de sesión o permanentes.<br/>
            Las cookies de sesión expiran cuando se cierra el navegador. Las cookies permanentes expiran cuando se cumple el objetivo para el que sirven, por ejemplo, para que el usuario se mantenga identificado en el sitio web, o bien cuando se borran manualmente.<br/>
            Adicionalmente, en función de su objetivo, las cookies puedes clasificarse de la siguiente forma:</p>
            <ul>
                <li>
                    <b>Cookies de registro: </b>Son aquellas estrictamente necesarias e imprescindibles para el correcto funcionamiento de la página. Normalmente, se generan al acceder o iniciar sesión en el sitio web y se utilizan para identificarle, en dicho sitio web, con los siguientes objetivos:<br/>
                    <ul>
                        <li>Mantenerle identificado de forma que, si abandona el sitio web y en otro momento vuelve a acceder, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse.</li>
                        <li>Comprobar si está autorizado para acceder a ciertos servicios o zonas del sitio web.</li>
                    </ul>
                </li>
                <li>
                    <b>Cookies de rendimiento: </b>Se utilizan para mejorar su experiencia de navegación y optimizar el funcionamiento del sitio web como, por ejemplo, almacenar las configuraciones de servicios o la compra realizada en un carrito de la compra.
                </li>
                <li>
                    <b>Cookies de publicidad: </b>Son aquellas que recaban información sobre los anuncios que se muestran en el sitio web y pueden ser de dos tipos:<br/>
                    <ul>
                        <li>Anónimas: Solo recopilan información sobre los espacios publicitarios mostrados en el sitio web, sin identificarle expresamente.</li>
                        <li>Personalizadas: Su información personal en el sitio web es recopilada por parte de terceros, para la personalización de los espacios publicitarios.</li>
                    </ul>
                </li>
                <li>
                    <b>Cookies de geolocalización: </b>Se utilizan para averiguar en qué país o región se encuentra cuando accede a un servicio del sitio web con el fin de ofrecerle contenidos o servicios adecuados a su localización.
                </li>
                <li>
                    <b>Cookies analíticas: </b>Recopilan información sobre su experiencia de navegación en el sitio web, normalmente de forma anónima; aunque, en ocasiones, también permite identificarle de manera única e inequívoca con el fin de obtener informes sobre sus intereses en los servicios que ofrece el sitio web.
                </li>
            </ul>
            <h4>Deshabilitar el uso de cookies</h4>
            <p>Si así lo desea, es posible dejar de aceptar las cookies del navegador o de un servicio en particular.<br/>
            Todos los navegadores permiten cambiar la configuración de las cookies. Estos ajustes normalmente se encuentran en "Opciones" o "Preferencias" del menú del navegador.<br/>
            En cualquier momento puede deshabilitar el uso de cookies en este sitio web:</p>
            <ul>
                <li>Google Chrome: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">Enlace</a></li>
                <li>Internet Explorer: <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11">Enlace</a></li>
                <li>Mozilla Firefox: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Enlace</a></li>
                <li>Apple Safari: <a href="https://support.apple.com/kb/PH17191?locale=es_ES">Enlace</a></li>
            </ul>
            <h4>¿Qué ocurre al deshabilitar las Cookies?</h4>
            <p>Algunas funcionalidades y servicios pueden quedar deshabilitados o tener un comportamiento diferente al esperado como, por ejemplo, permanecer identificado, mantener las compras en el “carrito de la compra” en un servicio de comercio electrónico o recibir información dirigida a su localización, entre otros.<br/>
            Si deshabilita el uso de cookies en este sitio web es probable que deje de poder acceder a ciertas zonas del mismo, o que se degrade notablemente su experiencia de navegación.</p>
            <h4>Cookies utilizadas en este sitio web</h4>
            <p>A continuación, se muestra una tabla con las cookies utilizadas en este sitio web que incorpora un criterio de “nivel de intrusividad” apoyado en una escala del 1 al 3, en la que:</p>
            <ul>
                <li><b>Nivel 1 o no intrusivas: </b>Son cookies inherentes al sistema y su bloqueo provocaría, en este sitio web, no poder acceder a la zona privada.</li>
                <li><b>Nivel 2 o mínimamente intrusivas: </b>Son cookies de uso propio vinculadas a servicios de terceros como, por ejemplo, analíticas y estadísticas. En este sitio web se utilizan cookies de Google Analytics.</li>
                <li><b>Nivel 3 o moderadamente intrusivas: </b>Son cookies proporcionadas, administradas y utilizadas por terceros con el fin de obtener datos para emplazamientos publicitarios o el uso de contenido de carácter social (redes sociales), y deben ser expresamente autorizadas por el usuario. Este sitio web no utiliza cookies de este tipo.</li>
            </ul>
            <table>
                <tr>
                    <th>Cookie</th>
                    <th>Duración</th>
                    <th>Finalidad</th>
                    <th>Nivel</th>
                </tr>
                <tr>
                    <td>JSESSIONID</td>
                    <td>Fin de sesión</td>
                    <td>Mantener la sesión del usuario</td>
                    <td>Nivel 1</td>
                </tr>
                <tr>
                    <td>cookiesDisclosureCount</td>
                    <td>1 día</td>
                    <td>Contabilizar el número de veces que se muestra el banner de “política de cookies”</td>
                    <td rowspan="2">Nivel 2</td>
                </tr>
                <tr>
                    <td>cookiesDirective</td>
                    <td>1 año</td>
                    <td>Identificar la aceptación del uso de cookies por parte del usuario</td>
                </tr>
                <tr>
                    <td>_gat</td>
                    <td>1 minuto</td>
                    <td rowspan="3">Recopilar información para mediciones internas con Google Analytics</td>
                    <td rowspan="3">Nivel 2</td>
                </tr>
                <tr>
                    <td>_gid</td>
                    <td>2 días</td>
                </tr>
                <tr>
                    <td>_ga</td>
                    <td>2 años</td>
                </tr>
                <tr>
                    <td>1P_JAR</td>
                    <td>1 mes</td>
                    <td>Utilizada por Google para recopilar estadísticas del sitio web</td>
                    <td></td>
                </tr>
                <tr>
                    <td>NID</td>
                    <td>6 meses</td>
                    <td rowspan="2">Utilizadas por Google para realizar un recuento del número de usuarios que utilizan los mapas</td>
                    <td rowspan="2">Nivel 2</td>
                </tr>
                <tr>
                    <td>SIDCC</td>
                    <td>6 meses</td>
                </tr>
                <tr>
                    <td>APISID</td>
                    <td>2 años</td>
                    <td rowspan="4">Utilizadas por Google para almacenar las preferencias del usuario y la información durante la visualización de las páginas que contienen mapas de Google</td>
                    <td rowspan="4">Nivel 2</td>
                </tr>
                <tr>
                    <td>HSID</td>
                    <td>2 años</td>
                </tr>
                <tr>
                    <td>SSID</td>
                    <td>2 años</td>
                </tr>
                <tr>
                    <td>SID</td>
                    <td>2 años</td>
                </tr>
            </table>
            <h4>Cookies de Google Analytics</h4>
            <p>Este sitio web utiliza Google Analytics, un servicio analítico web prestado por Google, Inc. (en adelante "Google"), una compañía de Delaware, cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos.<br/>
            Las cookies de Google Analytics analizan el uso que los usuarios hacen del sitio web, recopilan informes de actividad y prestan servicios relacionados con la actividad del sitio web y el uso de Internet.<br/>
            La información generada por la cookie se transmite y archiva directamente en los servidores de Google en Estados Unidos. Su dirección IP no se asociará con ningún otro dato del que disponga Google.<br/>
            Google comunicará esta información a terceros que procesan la información por cuenta de Google y para el cumplimiento de obligaciones legales.</p>
            <h4>Actualización de la política de cookies</h4>
            <p>Camino Aplicación, S.L. puede modificar esta política de cookies en función de exigencias legislativas o reglamentarias. Cuando se produzcan cambios significativos, la política se comunicará a los usuarios a través de la web y a los usuarios registrados mediante correo electrónico.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>