<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=2 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="faq-content">
        <h2>Preguntas frecuentes</h2>
        <span class="subtitle">Dudas y preguntas relacionadas con el camino</span>
        <div class="tab-content">
            <p-tabView>
                <p-tabPanel header="El Camino" leftIcon="pi pi-directions">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Título 1">
                            Texto 1
                        </p-accordionTab>
                        <p-accordionTab header="Título 2">
                            Texto 2
                        </p-accordionTab>
                        <p-accordionTab header="Título 3">
                            Texto 3    
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
                <p-tabPanel header="Medios de realización" leftIcon="pi pi-user">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Título 4">
                            Texto 4
                        </p-accordionTab>
                        <p-accordionTab header="Título 5">
                            Texto 5
                        </p-accordionTab>
                        <p-accordionTab header="Título 6">
                            Texto 6    
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
                <p-tabPanel header="Pagos" leftIcon="pi pi-dollar">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Título 7">
                            Texto 7
                        </p-accordionTab>
                        <p-accordionTab header="Título 8">
                            Texto 8
                        </p-accordionTab>
                        <p-accordionTab header="Título 9">
                            Texto 9    
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
                <p-tabPanel header="Validación de la credencial" leftIcon="pi pi-chevron-circle-down">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Título 10">
                            Texto 10
                        </p-accordionTab>
                        <p-accordionTab header="Título 11">
                            Texto 11
                        </p-accordionTab>
                        <p-accordionTab header="Título 12">
                            Texto 12   
                        </p-accordionTab>
                    </p-accordion>
                </p-tabPanel>
            </p-tabView>
        </div>




    </section>
    <app-footer></app-footer>
</div>
