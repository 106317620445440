import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { SelloUsuarioDto } from '../models/sello-usuario-dto';

@Injectable({
  providedIn: 'root'
})
export class SelloService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de sellos de una credencial
   * @param idCredencial identificador de credencial
   * @param onSuccess respuesta OK (datos de los sellos)
   * @param onError respuesta KO
   */
   public getSellosCredencialList(idCredencial: number, onSuccess:WsSuccessFunction<SelloUsuarioDto[]>, onError: WsErrorFunction<boolean>): void {

    const body = {
      idCredencial: idCredencial
    };

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.post<SelloUsuarioDto[]>(environment.apiSelllosListUrl, body, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }
}