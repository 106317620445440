<div class="recover-content">
    <div class="recover-content-overlay">
        <div class="recover-box card">
            <div class="card-body">
                <img src="../../../assets/img/camino_logo_color.png" routerLink="/inicio" alt="Covadonga"/>
                <div class="title">Introduce tu email de usuario para recibir en tu correo las instrucciones de recuperación de contraseña</div>
                <div class="form-content">
                    <form [formGroup]="form" novalidate autocomplete="off" (submit)="recover()">
                        <div class="item-line">
                            <input [readOnly]="inuse" formControlName="email" id="emailUsuario" placeholder="{{'recover_emailUsuario'|locale}}" type="text" required>
                            <!--pintar errores en campo si formulario enviado o campo modificado-->
                            <!--TODO: candidata a componente-->
                            <ng-container *ngIf="form.invalid && form.controls['email'].invalid && (submitted || form.controls['email'].dirty)">
                                <span *ngIf="form.controls['email'].errors?.required">{{'recover_errors_required'|locale}}</span>
                                <span *ngIf="form.controls['email'].errors?.pattern">{{'recover_errors_email'|locale}}</span>
                            </ng-container>
                        </div>
                       
                        <!--habilitar envío si formulario válido-->
                        <input [disabled]="inuse||!form.valid" value="{{'recover_submit'|locale}}" type="submit">

                        <div class="signup-content">
                            <div>¿Ya tienes cuenta?<a routerLink="/login"><b>Inicia sesión</b></a></div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>