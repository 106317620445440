import { Component, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';
import { TextUtils } from './utils/text-utils';
import { LocalStorageUtils, LocalStorageInfo } from './utils/local-storage-utils';
import { ArrayUtils } from './utils/array-utils';
import { HttpErrorResponse } from '@angular/common/http';

import  { GlobalResponse } from './models/global-response';

import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';

import { google } from '@google/maps';
import { AuthInfo } from './models/auth-info';

@Component({ selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'] })
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  
  public loaded: boolean; // registro de componente cargado

  /**
   * Constructor de aplicación
   * @param authService Servicio de autenticación
   * @param sessionService Servicio de sesión
   * @param router Servicio de enrutamiento
   * @param loadingService Servicio de carga dinámica
   */
  public constructor(private authService: AuthService, private sessionService: SessionService, private router: Router, private loadingService: LoadingService) { }


  public ngOnInit(): void {

    this.loaded = false;
    this.loadAuth();
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  private loadAuth(): void {

    const local = LocalStorageUtils.load() as LocalStorageInfo;
    if (TextUtils.isBlank(local.accessToken)) { this.onEndInit(); return; }

    this.authService.check(local.accessToken,

      (resp: AuthInfo) => {
        if (resp.valid != null && resp.valid == true) {
          const session = LocalStorageUtils.load();
          session.accessToken = resp.token;
          LocalStorageUtils.save(session);
          this.sessionService.saveSessionInfo(session);
          this.onEndInit();
        }
        else {
          this.processAuthCheckError(true, null); 
          this.onEndInit();
        }
      },
      (resp: boolean, err: HttpErrorResponse) => { 
        this.processAuthCheckError(resp, err); 
        this.onEndInit(); 
      });
  }

  private processAuthCheckError(resp: boolean, err: HttpErrorResponse): void {

    // Comprobación tiempos expiración sesión
    if (resp != null) {

      //Limpieza del token de sesión local
      const session = LocalStorageUtils.load();
      session.accessToken = undefined;
      LocalStorageUtils.save(session);
    }
  }

  public onEndInit() { 
    this.loaded = true; 
    this.loadingService.setLoading(); 
    this.router.initialNavigation();
  }

  public ngOnChanges(changes: SimpleChanges): void { }

  public ngOnDestroy(): void { }
}
