import { Subscription } from "rxjs";

/**
 * Gestión de suscripciones a componentes
 */
export class SubscriptionManager {
    private subscriptions: Array<Subscription> = new Array<Subscription>();

    /**
     * Añadir nueva suscripción
     * @param subscription suscripción
     */
    public add(subscription: Subscription): void {
        this.subscriptions.push(subscription);
    }

    public unsubscribeAll(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }
}
