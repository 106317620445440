import { Injectable } from '@angular/core';
import { Locale } from '../locales/locale';
import { LocaleEs } from '../locales/locale-es';
import { ArrayUtils } from '../utils/array-utils';

@Injectable({ providedIn: 'root' })
export class LocaleService {

  /**
   * Máximo idiomas en Caché
   */
  private static MAXCACHE = 10;

  /**
   * Declarar caché de idiomas disponibles
   */
  private cache: Locale[] = [new LocaleEs()];

  /**
   * Establecer idioma
   */
  private current: Locale = this.cache[0];

  /**
   * Recuperar idioma actual
   */
  public getCurrent(): Locale {
    return this.current;
  }

  /**
   * Setear nuevo idioma
   * @param locale idioma nuevo
   */
  public setCurrent(locale: Locale): void {

    // Buscar idioma en la lista
    const plocale = ArrayUtils.indexOf(this.cache, i => i.locale_lang === locale.locale_lang);
    // eliminar anterior
    if (plocale >= 0) { this.cache.splice(plocale, 1); }
    // insertar al inicio de la lista
    this.cache.unshift(locale);
    // actualizar idioma
    this.current = this.cache[0];
    // eliminar idiomas antiguos no utilizados
    if (this.cache.length > LocaleService.MAXCACHE) { this.cache.splice(LocaleService.MAXCACHE); }
  }

  /**
   * Recuperar un idioma de la cache del servicio
   * @param lang identificador del idioma
   */
  public getLocale(lang: string): Locale {

    // buscar idioma en la lista
    const plocale = ArrayUtils.indexOf(this.cache, i=> i.locale_lang === lang);

    // comprobar si existe
    if (plocale < 0) { return null; }

    // retornar idioma recuperado
    return this.cache[plocale];
  }
}
