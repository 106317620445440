import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CategoriaDto } from '../models/categoria-dto';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ServiciosCategoriasService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de categorias disponibles
   * @param onSuccess respuesta OK (datos de categorias)
   * @param onError respuesta KO
   */
   public getCategoriasAplicacionList(onSuccess:WsSuccessFunction<CategoriaDto[]>, onError: WsErrorFunction<boolean>): void {

    const body = {
      tipo: '',
      aplicacion: environment.applicationName
    };

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.post<CategoriaDto[]>(environment.apiServicioCategoriasListUrl, body, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }
}
