import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { GlobalResponse } from "../models/global-response";

/**
 * Callback para respuesta REST de éxito
 */
export type WsSuccessFunction<T> = (resp: T) => any;

/**
 * Callback para respuesta REST de fallo
 */
export type WsErrorFunction<T> = (resp: T, err: HttpErrorResponse) => any;


/**
 * Encapsulación de parámetros
 */
export interface HttpParamsWrapper { params: HttpParams; }


export class WebServiceUtils {

    public static HEADER_CONTENT_TYPE = "Content-Type";
    public static CONTENT_TYPE = "application/json";

    /**
     * Comprobación respuesta válida servicio REST
     * @param resp respuesta a comprobar
     */
    public static isValid(resp: GlobalResponse<any>): boolean {

        // si no hay respuesta
        if (resp == null) { return false; }

        // si la respuesta contiene errores
        if (resp.errors != null) { return false; }

        return true;
    }
}
