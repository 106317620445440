export class ArrayUtils {

    /**
     * Comprobación de array de elementos vacío
     * @param collection array a comprobar
     */
    public static isEmpty<T>(collection: T[]): boolean {

        return this.size(collection) === 0;
    }

    /**
     * Cálculo del tamaño de un array con comprobación de null element
     * @param collection array de elementos
     */
    public static size<T>(collection: T[]): number {

        return collection != null ? collection.length : 0
    }

    /**
     * Buscar en un array el primer elemento que cumple una propiedad
     * @param collection listado de elementos
     * @param f condicion de evaluacion
     */
    public static indexOf<T>(collection: T[], f: (item: T) => boolean): number {

        // Localizar parámetro con condición
        for (let i = 0; i < collection.length; i++) { if (f(collection[i])) { return i; }}

        // Retornar no encontrado
        return -1;
    }
}
